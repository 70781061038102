import { Component, Vue } from 'vue-property-decorator'

//global
import { Variables } from '@/config/variables';

//esic components
import EContent from '@/components/esic/eContent/EContent.vue';
import ECard from '@/components/esic/eCard/ECard.vue';

//wrappers
import {
	WColumn,
	WColumns,
	SizedBox,
	WLine,
	wIcon,
	SmoothDropdown,
	WButton,
	WImage,
} from '@/components/wrappers';

@Component({
	components:{
		ECard,
		WLine,
		wIcon,
		WImage,
		WColumn,
		WButton,
		WColumns,
		SizedBox,
		EContent,
		SmoothDropdown,
	}
})
export default class Esic extends Vue {
	textLaw = Variables.htmlTextLaw

	mouted(): void {
		localStorage.clear()
		sessionStorage.clear()
		document.title = "e-OUV"
	}

	cards = [
		{
			title:"Denúncia",
			icon: "bullhorn",
			route: "/ouvidoria",
			enabled: true
		},
		{
			title:"Reclamação",
			icon: "thumb-down",
			route: "/ouvidoria",
		},
		{
			title:"Solicitação",
			icon: "hand-pointing-up",
			route: "/ouvidoria",
		},
		{
			title:"Sugestão",
			icon: "comment",
			route: "/ouvidoria",
		},
		{
			title:"Elogio",
			icon: "thumb-up",
			route: "/ouvidoria",
			enabled: true
		},
		{
			title:"Consultar",
			icon: "magnify",
			route: "/recognition",
		},
	]
}
